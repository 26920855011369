<template>
    <div
        class="transform-card-conintainer"
        :style="{'background-image': props.backgroundImage ? `url('${props.backgroundImage}')` : 'none'}"
        @mouseenter="onMouseover"
        @mouseleave="onMouseleave"
    >
        <div class="transform-mask" />
        <div ref="container" class="transform-card" :class="{ 'flex-reverse': reverse }">
            <div class="transform-card-content">
                <slot name="transform-card-content" />
            </div>
            <div ref="target" class="transform-card-title-container">
                <div class="transform-card-title">
                    <slot name="transform-card-title" />
                </div>
                <div ref="follow" class="transform-card-title-follow">
                    <slot name="transform-card-follow" />
                </div>
                <div ref="faded" class="transform-card-title-faded">
                    <slot name="transform-card-faded" />
                </div>
            </div>

            <!-- display: absolute -->
            <div
                class="transform-card-arrow"
                :class="{
                    'transform-card-arrow-right-bottom': !reverse,
                    'transform-card-arrow-right-top': reverse,
                }"
            >
                <CommonComponentsSVGCommonCircleArrow v-if="!hideArrow" />
            </div>
            <div class="transform-card-icon">
                <slot name="transform-card-icon" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { matchMobile } from '~~/composables/useMatchRules.ts';

    gsap.registerPlugin(ScrollTrigger);
    // const hover = ref(false);
    const props = withDefaults(
        defineProps<{
            reverse?: boolean; // 翻转方向是否反转
            backgroundImage?: string;
            hideArrow?: boolean;
        }>(),
        {
            reverse: false,
            hideArrow: false,
        },
    );

    const isMobile = ref<boolean>(false);
    const directions = computed(() => (props.reverse ? -1 : 1));
    const target = ref(null); // hover状态下呈现移动变化的目标dom
    const follow = ref(null); // hover状态下跟随target移动的dom
    const faded = ref(null); // hover状态下淡出
    const container = ref(null);

    const onMouseover = () => {
        if (!isMobile.value) {
            gsap.timeline()
                .to(target.value, {
                    y: () => -directions.value * container.value.offsetHeight,
                    yPercent: directions.value * 100,
                    delay: 0.2,
                })
                .to(
                    faded.value,
                    {
                        opacity: 0,
                    },
                    '<',
                )
                .to(
                    follow.value,
                    {
                        opacity: 1,
                    },
                    '<',
                );
        }
    };

    const onMouseleave = () => {
        if (!isMobile.value) {
            gsap.timeline()
                .to(target.value, {
                    y: 0,
                    yPercent: 0,
                    delay: 0.2,
                })
                .to(
                    faded.value,
                    {
                        opacity: 1,
                    },
                    '<',
                )
                .to(
                    follow.value,
                    {
                        opacity: 0,
                    },
                    '<',
                );
        }
    };

    onBeforeMount(() => {
        isMobile.value = matchMobile();
    });
</script>

<style lang="less" scoped>
    @transform-duration: 0.5s;
    @transition-delay: 0.2s;
    .flex-reverse {
        flex-direction: column-reverse !important;
    }
    .transform-card-conintainer {
        position: relative;
        overflow: hidden;
        color: #ffffff;
        width: 100%;
        height: 100%;
        background: #161616;
        border-radius: 20px;
        padding: .convert(24px) [ @vw];
        transition: all;
        transition: @transform-duration;
        background-size: cover;
        background-repeat: no-repeat;
        font-family: Montserrat, 'HarmonyOS Sans SC';

        // 移动端不使用hover效果
        @media (min-width: @screen-mobile-width) {
            // padding: ;
            &:hover {
                cursor: pointer;
                .transform-mask {
                    background: #0032ff;
                }

                .transform-card {
                    .transform-card-arrow {
                        opacity: 1;
                    }
                    .transform-card-content,
                    .transform-card-subtitle,
                    .transform-card-icon {
                        opacity: 0;
                    }
                }
            }
        }
        .transform-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: all;
            transition: @transform-duration;
            transition-delay: @transition-delay;
            top: 0;
            left: 0;
        }
        .transform-card {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            position: relative;
            height: 100%;
            width: 100%;

            &-content,
            &-subtitle,
            &-arrow,
            &-title,
            &-icon {
                transition: all;
                transition: @transform-duration;
                transition-delay: @transition-delay;
            }

            .transform-card-title-container {
                .transform-card-title {
                    font-weight: 400;
                    font-size: .convert(@font-size-title-xl) [ @vw];
                    line-height: normal;
                }
                .transform-card-title-follow {
                    position: absolute;
                    opacity: 0;
                }
                .transform-card-title-faded {
                    display: block;
                    opacity: 1;
                }
            }

            .transform-card-content {
                width: 100%;
            }

            .transform-card-arrow {
                position: absolute;
                opacity: 0;

                :deep(.svg-icon) {
                    width: .convert(84px) [ @vw];
                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(@font-size-lg-ssm) [ @vw-mobile];
                        width: .convert(40px) [ @vw-mobile];
                    }
                }
            }

            .transform-card-icon {
                position: absolute;
                top: 0;
                right: 0;
            }

            .transform-card-arrow-right-bottom {
                right: .convert(20px) [ @vw];
                bottom: .convert(20px) [ @vw];
            }

            .transform-card-arrow-right-top {
                right: .convert(20px) [ @vw];
                top: .convert(20px) [ @vw];
            }
        }
    }
</style>
