<template>
    <svg class="svg-icon" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M62.9717 60.5886H25.768V23.8713" stroke="white" stroke-width="4" stroke-linecap="round" />
        <path d="M26.0717 60.5184L60.175 26.415" stroke="white" stroke-width="4" stroke-linecap="round" />
        <path
            d="M42 82.0001C64.0914 82.0001 82 64.0914 82 42C82 19.9086 64.0914 2 42 2C19.9086 2 2 19.9086 2 42C2 64.0914 19.9086 82.0001 42 82.0001Z"
            stroke="white"
            stroke-width="4"
        />
    </svg>
</template>
